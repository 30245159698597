import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

export const StatusQuoIcon = props => (
  <svg viewBox="0 0 348 160" {...props}>
    <g transform="translate(-193 -737)">
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(386.155 792.3)"
        fill="#1d1d1d"
      />
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(337.891 847.804)"
        fill="#1d1d1d"
      />
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(386.155 847.804)"
        fill="#1d1d1d"
      />
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(193 737.333)"
        fill="#1d1d1d"
      />
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(193 792.3)"
        fill="#1d1d1d"
      />
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(193 847.804)"
        fill="#1d1d1d"
      />
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(241.297 737.333)"
        fill="#1d1d1d"
      />
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(241.297 792.3)"
        fill="#1d1d1d"
      />
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(241.297 847.804)"
        fill="#1d1d1d"
      />
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(289.594 737.333)"
        fill="#1d1d1d"
      />
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(289.594 792.3)"
        fill="#1d1d1d"
      />
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(289.594 847.804)"
        fill="#1d1d1d"
      />
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(337.891 737.333)"
        fill="#1d1d1d"
      />
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(386.155 737.333)"
        fill="#1d1d1d"
      />
      <path
        d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
        transform="translate(380.875 836.829) rotate(-180)"
        fill="#00edae"
      />
    </g>
  </svg>
)

export const TeamUpIcon = props => (
  <svg viewBox="0 0 348 160" {...props}>
    <g transform="translate(-193 -1235)">
      <g>
        <path
          id="Path_4091"
          style={{ fill: `#1d1d1d` }}
          d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
          transform="matrix(-0.259, -0.966, 0.966, -0.259, 219.438, 1371.088)"
        />
        <path
          id="Path_4092"
          style={{ fill: `#00edae` }}
          d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
          transform="translate(304.875 1393.83) rotate(-180)"
        />
        <path
          id="Path_4099"
          style={{ fill: `#1d1d1d` }}
          d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
          transform="translate(401.875 1305.83) rotate(-180)"
        />
        <path
          id="Path_4097"
          style={{ fill: `#00edae` }}
          d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
          transform="matrix(0.809, 0.588, -0.588, 0.809, 273.08, 1274.919)"
        />
        <path
          id="Path_4100"
          style={{ fill: `#00edae` }}
          d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
          transform="matrix(0.809, 0.588, -0.588, 0.809, 458.08, 1325.919)"
        />
        <path
          id="Path_4093"
          style={{ fill: `#1d1d1d` }}
          d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
          transform="translate(330.806 1359.609) rotate(-73)"
        />
        <path
          id="Path_4096"
          style={{ fill: `#00edae` }}
          d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
          transform="matrix(0.259, 0.966, -0.966, 0.259, 346.325, 1243.041)"
        />
        <path
          id="Path_4098"
          style={{ fill: `#00edae` }}
          d="M24.422.108C11.845,1.73,0,9.166,0,21.089S9.2,42.735,18.749,44.356s25.017-8.416,24.206-18.633S37-1.514,24.422.108ZM21.586,37.281c-7.179,0-13-3.452-13-7.712,0-2.976,6.336-5.556,10.159-6.841a7.712,7.712,0,1,1,5.672,0c3.824,1.286,10.16,3.865,10.16,6.841C34.581,33.829,28.764,37.281,21.586,37.281Z"
          transform="translate(210.889 1274.3)"
        />
      </g>
    </g>
  </svg>
)

export const ATeamIcon = props => (
  <svg viewBox="0 0 348 160" {...props}>
    <g id="A-player" transform="translate(-193 -1706)">
      <g
        id="Rectangle_A-player"
        data-name="Rectangle A-player"
        transform="translate(-971.516 1572.248)">
        <line
          id="Line_25"
          data-name="Line 25"
          y1="31.265"
          transform="translate(1234.016 141.751)"
          fill="none"
          stroke="#00edae"
          strokeLinecap="round"
          strokeWidth="5"
        />
        <line
          id="Line_26"
          data-name="Line 26"
          y1="31.265"
          transform="translate(1271.36 149.056) rotate(30)"
          fill="none"
          stroke="#00edae"
          strokeLinecap="round"
          strokeWidth="5"
        />
        <line
          id="Line_27"
          data-name="Line 27"
          y2="31.265"
          transform="translate(1210.567 176.132) rotate(150)"
          fill="none"
          stroke="#00edae"
          strokeLinecap="round"
          strokeWidth="5"
        />
        <line
          id="Line_28"
          data-name="Line 28"
          y2="31.265"
          transform="translate(1197.183 191.254) rotate(120)"
          fill="none"
          stroke="#00edae"
          strokeLinecap="round"
          strokeWidth="5"
        />
        <line
          id="Line_29"
          data-name="Line 29"
          x1="0.001"
          y1="31.264"
          transform="translate(1297.925 175.622) rotate(60)"
          fill="none"
          stroke="#00edae"
          strokeLinecap="round"
          strokeWidth="5"
        />
        <g id="Group_190" data-name="Group 190" transform="translate(1187.112 193.197)">
          <path
            id="Path_4109"
            data-name="Path 4109"
            d="M1230.827,275.646c0,5.027-3.186,9.713-8.007,10.912a10.722,10.722,0,0,1-13.154-8.176,18.492,18.492,0,0,1-14.156-16.345,10.843,10.843,0,0,1-5.292-15.924,24.113,24.113,0,0,1-4.919-14.666,23.8,23.8,0,0,1,11.3-20.413c-.025-.3-.089-.584-.089-.889a10.782,10.782,0,0,1,10.712-10.851,10.509,10.509,0,0,1,2.881.446c2.053-3.069,8.833-7.241,12.7-7.241,5.851,0,7.376,6.761,8.016,12.665"
            transform="translate(-1185.3 -192.5)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4110"
            data-name="Path 4110"
            d="M1221.66,278.382a18.5,18.5,0,0,0,14.156-16.345,10.838,10.838,0,0,0,5.289-15.924,24.115,24.115,0,0,0,4.922-14.666,23.8,23.8,0,0,0-11.3-20.413c.025-.3.087-.584.087-.889A10.781,10.781,0,0,0,1224.1,199.3a10.5,10.5,0,0,0-2.878.446c-2.057-3.069-7.625-7.241-11.5-7.241-5.851,0-8.588,6.761-9.224,12.665v70.481c0,5.027,3.185,9.713,8,10.912A10.724,10.724,0,0,0,1221.66,278.382Z"
            transform="translate(-1153.765 -192.5)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4111"
            data-name="Path 4111"
            d="M1206.252,213.568a6.395,6.395,0,1,1-12.788,0"
            transform="translate(-1168.362 -148.79)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4112"
            data-name="Path 4112"
            d="M1193.464,208a6.436,6.436,0,0,1,6.392-6.478"
            transform="translate(-1168.362 -173.778)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4113"
            data-name="Path 4113"
            d="M1195.543,215.769a6.534,6.534,0,0,1,0,9.163"
            transform="translate(-1164.049 -144.224)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4114"
            data-name="Path 4114"
            d="M1190.284,210.589a6.538,6.538,0,0,1,0,9.16"
            transform="translate(-1174.96 -154.971)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4115"
            data-name="Path 4115"
            d="M1197.52,210.521a6.54,6.54,0,0,1,9.162,0"
            transform="translate(-1159.947 -158.996)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4116"
            data-name="Path 4116"
            d="M1188.976,198.528c2.5,2.53,2.5,12.782,0,15.309"
            transform="translate(-1177.674 -179.994)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4117"
            data-name="Path 4117"
            d="M1195.543,197.055a13.525,13.525,0,0,1,0,13.741"
            transform="translate(-1164.049 -183.05)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4118"
            data-name="Path 4118"
            d="M1202.59,213.568a6.394,6.394,0,1,0,12.788,0"
            transform="translate(-1149.429 -148.79)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4119"
            data-name="Path 4119"
            d="M1211.062,208a6.435,6.435,0,0,0-6.392-6.478"
            transform="translate(-1145.113 -173.778)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4120"
            data-name="Path 4120"
            d="M1205.935,215.769a6.535,6.535,0,0,0,0,9.163"
            transform="translate(-1146.378 -144.224)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4121"
            data-name="Path 4121"
            d="M1211.193,210.589a6.537,6.537,0,0,0,0,9.16"
            transform="translate(-1135.466 -154.971)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4122"
            data-name="Path 4122"
            d="M1209.662,207.323a6.54,6.54,0,0,0-9.162,0"
            transform="translate(-1153.765 -165.633)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4123"
            data-name="Path 4123"
            d="M1212.9,198.528c-2.5,2.53-2.5,12.782,0,15.309"
            transform="translate(-1131.936 -179.994)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_4124"
            data-name="Path 4124"
            d="M1205.935,197.055a13.527,13.527,0,0,0,0,13.741"
            transform="translate(-1146.378 -183.05)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
        </g>
      </g>
      <rect
        id="Rectangle_81"
        data-name="Rectangle 81"
        width="348"
        height="160"
        transform="translate(193 1706)"
        fill="none"
      />
    </g>
  </svg>
)

export const SolveProblemIcon = props => (
  <svg viewBox="0 0 348 160" {...props}>
    <g id="Solve_problems" data-name="Solve problems" transform="translate(-193 -2219)">
      <g
        id="Rectancgle_Solve_problems"
        data-name="Rectancgle Solve problems"
        transform="translate(-935.029 1123.161)">
        <path
          id="Path_4101"
          data-name="Path 4101"
          d="M1216.083,1204.006a33.458,33.458,0,0,0,0-16.412l11.16-6.443-9.61-16.656-11.162,6.444a34.429,34.429,0,0,0-14.214-8.217v-12.875l-19.231-.006v12.881a34.4,34.4,0,0,0-14.22,8.215l-11.156-6.442-9.618,16.656,11.162,6.443a33.427,33.427,0,0,0,0,16.412l-11.162,6.444,9.612,16.654,11.161-6.437a34.409,34.409,0,0,0,14.214,8.215v12.879h19.232l0-12.881a34.405,34.405,0,0,0,14.216-8.213l11.157,6.441,9.618-16.656Zm-33.447,10.593a18.8,18.8,0,1,1,.567-37.584,19,19,0,0,1,18.224,18.284A18.794,18.794,0,0,1,1182.636,1214.6Z"
          transform="translate(0 0)"
          fill="#1d1d1d"
        />
        <path
          id="Path_4102"
          data-name="Path 4102"
          d="M1305.356,1204.006a33.458,33.458,0,0,0,0-16.412l11.159-6.443-9.61-16.656-11.161,6.444a34.429,34.429,0,0,0-14.214-8.217v-12.875l-19.23-.006v12.881a34.4,34.4,0,0,0-14.22,8.215l-11.154-6.442-9.62,16.656,11.162,6.443a33.427,33.427,0,0,0,0,16.412l-11.162,6.444,9.612,16.654,11.162-6.437a34.41,34.41,0,0,0,14.214,8.215v12.879h19.233l0-12.881a34.406,34.406,0,0,0,14.216-8.213l11.157,6.441,9.618-16.656Zm-33.447,10.593a18.8,18.8,0,1,1,.568-37.584A19,19,0,0,1,1290.7,1195.3,18.794,18.794,0,0,1,1271.909,1214.6Z"
          transform="translate(89.426 0)"
          fill="#1d1d1d"
        />
        <path
          id="Path_4103"
          data-name="Path 4103"
          d="M1236.156,1161.677a33.453,33.453,0,0,0-16.41,0l-6.446-11.16-16.654,9.61,6.443,11.162a34.415,34.415,0,0,0-8.219,14.214H1182l0,19.231h12.879a34.383,34.383,0,0,0,8.217,14.22l-6.441,11.156,16.654,9.618,6.446-11.161a33.418,33.418,0,0,0,16.41,0l6.445,11.161,16.652-9.612-6.437-11.161a34.384,34.384,0,0,0,8.217-14.214h12.877v-19.233l-12.879,0a34.384,34.384,0,0,0-8.215-14.216l6.441-11.158-16.654-9.618Zm10.593,33.447a18.8,18.8,0,1,1-37.584-.567,19,19,0,0,1,18.283-18.224A18.794,18.794,0,0,1,1246.749,1195.123Z"
          transform="translate(44.037 0.677)"
          fill="#00edae"
          stroke="#1d1d1d"
          strokeWidth="5"
        />
      </g>
      <rect
        id="Rectangle_82"
        data-name="Rectangle 82"
        width="348"
        height="160"
        transform="translate(193 2219)"
        fill="none"
      />
    </g>
  </svg>
)

export const CraftIcon = props => (
  <svg viewBox="0 0 348 160" {...props}>
    <g id="Code_craft" data-name="Code craft" transform="translate(-193 -2638)">
      <circle
        id="Ellipse_38"
        data-name="Ellipse 38"
        cx="10"
        cy="10"
        r="10"
        transform="translate(273 2738)"
        fill="#00edae"
      />
      <g id="Rectangle_Code_craft" data-name="Rectangle Code craft">
        <line
          id="Line_22"
          data-name="Line 22"
          x1="35.352"
          transform="translate(204.148 2784)"
          fill="none"
          stroke="#00edae"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <line
          id="Line_23"
          data-name="Line 23"
          x1="44.326"
          transform="translate(204.148 2766.126)"
          fill="none"
          stroke="#00edae"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <line
          id="Line_24"
          data-name="Line 24"
          x1="44.326"
          transform="translate(204.148 2730.252)"
          fill="none"
          stroke="#00edae"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <line
          id="Line_30"
          data-name="Line 30"
          x1="44.326"
          transform="translate(204.148 2748.252)"
          fill="none"
          stroke="#00edae"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <g id="Group_189" data-name="Group 189" transform="translate(243.788 2658.953)">
          <g id="Group_188" data-name="Group 188">
            <path
              id="Path_4106"
              data-name="Path 4106"
              d="M1056.563,2477.059l-22.682,22.683L1000.2,2466.06l56.365-54.108"
              transform="translate(-958.964 -2411.952)"
              fill="none"
              stroke="#000"
              strokeLinejoin="bevel"
              strokeWidth="5"
            />
            <line
              id="Line_21"
              data-name="Line 21"
              y1="32.357"
              x2="33.321"
              transform="translate(0 95.69)"
              fill="none"
              stroke="#000"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <path
              id="Path_4107"
              data-name="Path 4107"
              d="M1013.145,2443.563a8.844,8.844,0,1,1,0-12.507A8.844,8.844,0,0,1,1013.145,2443.563Z"
              transform="translate(-967.312 -2347.872)"
              fill="none"
              stroke="#000"
              strokeLinejoin="bevel"
              strokeWidth="5"
            />
            <path
              id="Path_4108"
              data-name="Path 4108"
              d="M1049.006,2468l10.217-24.657-18.8-18.779-24.644,10.216h0l-24.028,56.285L1049.006,2468Z"
              transform="translate(-991.748 -2363.017)"
              fill="none"
              stroke="#000"
              strokeLinejoin="bevel"
              strokeWidth="5"
            />
          </g>
        </g>
      </g>
      <rect
        id="Rectangle_83"
        data-name="Rectangle 83"
        width="348"
        height="160"
        transform="translate(193 2638)"
        fill="none"
      />
    </g>
  </svg>
)

export const FWordsIcon = props => (
  <svg viewBox="0 0 348 160" {...props}>
    <g id="F_words" data-name="F words" transform="translate(-193 -3133)">
      <g id="Rectangle_F_words" data-name="Rectangle F words" transform="translate(10)">
        <path
          id="Path_4105"
          data-name="Path 4105"
          d="M1385.716,1153.067l28.293,28.29v-30.595c18.865-4.883,32.071-16.128,32.071-29.225,0-17.578-23.788-31.831-53.124-31.831s-53.129,14.253-53.129,31.831C1339.826,1137.645,1359.8,1150.949,1385.716,1153.067Z"
          transform="translate(-1087.566 2102.493)"
          fill="#00edae"
          stroke="#1d1d1d"
          strokeWidth="5"
        />
        <path
          id="Path_4104"
          data-name="Path 4104"
          d="M1412.836,1166.341l-34.22,34.217v-37c-22.817-5.906-38.79-19.507-38.79-35.348,0-21.26,28.771-38.5,64.254-38.5s64.26,17.239,64.26,38.5C1468.34,1147.688,1444.179,1163.779,1412.836,1166.341Z"
          transform="translate(-1146.826 2058.293)"
          fill="none"
          stroke="#1d1d1d"
          strokeWidth="5"
        />
      </g>
      <rect
        id="Rectangle_84"
        data-name="Rectangle 84"
        width="348"
        height="160"
        transform="translate(193 3133)"
        fill="none"
      />
    </g>
  </svg>
)

export const Belief = styled.h2`
  ${tw`pt-4 pb-4 text-2xl sm:text-4xl lg:text-5xl`}
`

export const BeliefDesc = styled.p`
  ${tw`text-lg sm:text-xl font-normal tracking-normal leading-30px`}
`
